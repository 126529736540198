import React, { useContext } from 'react';

import { ViewportEnter } from 'components/viewport-enter/ViewportEnter';

import { FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';
import { PledgeContext } from 'context/pledge';

import { Container } from 'components/layout/Container';
import { Row } from 'components/layout/Row';

import Arrow from 'assets/svg/icons/arrow.svg';

import s from './Item.scss';

interface IProps {
  index: number;
  heading: string;
  link?: string;
  count: number;
  itemCount: number;
}

export const Item = ({ heading, link, count, itemCount, index }: IProps) => {
  const { setCurrentStep, active } = useContext<any>(PledgeContext);
  const first = index === 0;
  const last = index + 1 === itemCount;

  let formattedNumber;
  if (count) {
    formattedNumber = count < 10 ? `0${count}` : count;
  }

  const onEnter = () => {
    setCurrentStep(index);
  };

  return (
    <>
      <div className={s('item', { first, last, active })}>
        <ViewportEnter threshold={0} onEnter={onEnter} once={false}>
          <div className={s.item__waypoint} />
        </ViewportEnter>
        <Container>
          <Row>
            <div className={s.item__content}>
              <div className={s.item__count}>{formattedNumber}</div>
              <p className={s.item__text}>{heading}</p>
              {link && (
                <Link className={s.item__link} to={link}>
                  <FormattedMessage id="read-more" />
                  <Arrow className={s.item__arrow} />
                </Link>
              )}
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};
