import React, { useState, useContext, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { FormattedMessage } from 'react-intl';

import { UIContext } from 'context/ui';
import { PledgeContext } from 'context/pledge';

import { Signature } from 'components/signature/Signature';

import s from './Outro.scss';

interface IProps {
  heading: string;
  completedHeading: string;
  text: string;
  completedText: string;
  count: number;
  image: string; // mobile only background
  share?: React.ReactElement<any>;
  onSignature: (signature: string) => Promise<number>;
  itemCount: number;
}

export const Outro = ({
  completedHeading,
  heading,
  completedText,
  text,
  count,
  image,
  share,
  onSignature,
  itemCount, // number of steps
}: IProps) => {
  const [complete, setComplete] = useState(false);
  const [internalCount, setInternalCount] = useState(count);
  const { isMobile } = useContext<any>(UIContext);
  const { outroVisible, setOutroVisible, currentStep } = useContext<any>(PledgeContext);

  useEffect(() => {
    setInternalCount(count);
  }, [count]);

  useEffect(() => {
    setOutroVisible(itemCount === currentStep);
  }, [currentStep]);

  async function setCompleteHandler(signature: string) {
    const currentCount = await onSignature(signature);
    setInternalCount(currentCount);
    setComplete(true);
  }

  const style = isMobile ? { backgroundImage: `url(${image})` } : undefined;

  return (
    // <ViewportEnter
    //   once={false}
    //   onEnter={() => {
    //     setOutroVisible(true);
    //     setCurrentStep(-1);
    //   }}
    //   onExit={() => {
    //     setOutroVisible(false);
    //   }}
    //   threshold={0.25}
    // >
      <div className={s('outro', { visible: outroVisible })}>
        <div className={s.outro__background} style={style} />

        <div className={s.outro__inner}>
          <div className={s.outro__container}>
            <div className={s.outro__containerInner}>
              {!complete && (
                <div className={s.outro__content}>
                  <h2 className={s.outro__heading}>{heading}</h2>
                  <p className={s.outro__text}>{text}</p>

                  <div className={s.outro__signature}>
                    <Signature
                      onComplete={setCompleteHandler}
                    />
                  </div>
                </div>
              )}

              <CSSTransition
                timeout={500}
                in={complete}
                unmountOnExit
                classNames={{ enter: s.enter, enterActive: s.enterActive }}
              >
                <div className={s.outro__content}>
                  <h2 className={s.outro__heading}>{completedHeading}</h2>
                  <p className={s.outro__text}>{completedText}</p>
                  <div className={s.outro__share}>{share}</div>
                </div>
              </CSSTransition>
            </div>
          </div>

          <CSSTransition
            timeout={5000}
            in={internalCount > 0}
            unmountOnExit
            classNames={{
              enter: s.enter,
              enterActive: s.enterActive,
              exit: s.exit,
            }}
          >
            <div className={s.outro__countWrap}>
              <div className={s.outro__count}>{count > 0 ? internalCount : ''} </div>
              <div className={s.outro__countText}>
                <FormattedMessage id="pledge-count" />
              </div>
            </div>
          </CSSTransition>
        </div>
      </div>
    // </ViewportEnter>
  );
};
