import { get, isEmpty } from 'lodash';
import React from 'react';
import RichText from 'components/rich-text/RichText';

import Meta from 'components/meta/Meta';

interface IProps {
  data: any;
}

export const MetaContainer = ({ data }: IProps) => {

  const seoTitle =
    RichText.asText(get(data, 'seo_title', '')) ||
    RichText.asText(get(data, 'title', ''));

  const seoDescription =
    RichText.asText(get(data, 'seo_description', '')) ||
    RichText.asText(get(data, 'description', ''));

  const seoImage = !isEmpty(data.seo_image) ?
    get(data, 'seo_image.url', '') :
    get(data, 'image.seo.url', get(data, 'image.url', ''));

  const seoImageAlt = !isEmpty(data.seo_image) ?
    get(data, 'seo_image.alt', '') :
    get(data, 'image.seo.alt', get(data, 'image.alt', ''));

  return (
    <Meta
      title={seoTitle}
      description={seoDescription}
      image={seoImage}
      imageAlt={seoImageAlt}
    />
  );
};
