import { graphql } from 'gatsby';

export const fragmentMeta = graphql`
  fragment meta on PRISMIC_Meta {
    id
    uid
    lang
    type
  }
`;

export const fragmentArticleBanner = graphql`
  fragment articleBanner on PRISMIC_Article {
    _linkType
    __typename
    _meta {
      ...meta
      type
    }
    title
    image
    image_author
    image_caption
    category {
      ... on PRISMIC_Article_category {
        title
      }
    }
    region {
      __typename
    }
  }
`;

export const fragmentRegion = graphql`
  fragment region on PRISMIC_Region {
    title
    _meta {
      ...meta
    }
  }
`;

export const fragmentImageRegion = graphql`
fragment imageRegion on PRISMIC_Region {
  _meta {
    uid
  }
}
`;

export const fragmentLink = graphql`
  fragment link on PRISMIC__Linkable {
    _linkType
    __typename
    ... on PRISMIC_Article {
      _meta {
        ...meta
      }
      title
      menu_label
    }
    ... on PRISMIC_Page_content {
      _meta {
        ...meta
      }
      title
    }
    ... on PRISMIC_Flights_to_iceland {
      _meta {
        ...meta
      }
      title
      menu_label
    }
    ... on PRISMIC_Regions_of_iceland {
      _meta {
        ...meta
      }
      title
      menu_label
    }
    ... on PRISMIC_Festivals {
      _meta {
        ...meta
      }
      title
      menu_label
    }
    ... on PRISMIC_Iceland_academy {
      _meta {
        ...meta
      }
      title
    }
    ... on PRISMIC_Icelandic_pledge {
      _meta {
        ...meta
      }
      title
      menu_label
    }
    ... on PRISMIC_A_o_overview {
      _meta {
        ...meta
      }
      title
      menu_label
    }
    ...on PRISMIC_About_iceland {
      _meta {
        ...meta
      }
      title
      menu_label
    }
    ...on PRISMIC_Things_to_do {
      _meta {
        ...meta
      }
      title
      menu_label
    }
    ...on PRISMIC_Plan_your_trip {
      _meta {
        ...meta
      }
      title
      menu_label
    }
    ...on PRISMIC_Iceland_academy {
      _meta {
        ...meta
      }
      title
      menu_label
    }
    ...on PRISMIC__ExternalLink {
      url
    }
    ...on PRISMIC__FileLink {
      url
    }
    ...on PRISMIC__Document {
      _meta {
        ...meta
      }
    }
    ...on PRISMIC__ImageLink {
      url
    }
  }
`;

export const fragmentFile = graphql`
  fragment file on PRISMIC__FileLink {
    name
    url
    size
  }
`;
