import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { get, isEmpty } from 'lodash';
import createLink from 'utils/createLink';

import { PledgeProvider } from 'context/pledge';

import RichText from 'components/rich-text/RichText';
import { HeroBackground } from 'components/hero-background/HeroBackground';

import { Hero } from 'components/pledge/hero/Hero';
import { Item } from 'components/pledge/item/Item';
import { Outro } from 'components/pledge/outro/Outro';
import { InlineShare } from 'components/inline-share/InlineShare';
import { Wrapper } from 'components/pledge/wrapper/Wrapper';

import { MetaContainer } from 'containers/meta/MetaContainer';
import { fragmentMeta, fragmentFile, fragmentLink } from 'fragments';

interface IProps {
  data: any;
  location: any;
}

async function addPledge(signature: string) {
  return request({
    signature,
  });
}

async function getPledgeCount() {
  return request();
}

async function request(body?: any): Promise<number> {
  const url = new URL('/.netlify/functions/pledges', window.location.origin);
  const options: any = {
    method: body ? 'POST' : 'GET',
    headers: {
      'content-type': 'application/json',
    },
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  try {
    const result = await fetch(url.href, options);

    if (!result.ok) {
      // tslint:disable-next-line
      console.error('Unable to count pledge', result.status, result.statusText);
    } else {
      const jsonData = await result.json();
      return jsonData.count;
    }
  } catch (e) {
    // tslint:disable-next-line
    console.error('Unable to count pledge', e);
  }

  return Promise.resolve(-1);
}

const IcelandicPledge = (props: IProps) => {
  const [count, setCount] = useState(-1);
  const { data } = props;
  const pageData = get(data, 'prismic.allIcelandic_pledges.edges.0.node', {});
  const items = get(pageData, 'list', []);

  useEffect(() => {
    async function fetch() {
      setCount(await getPledgeCount());
    }
    fetch();
  }, []);

  const renderItem = ({ item_link: link, item_title }: any, i: number) => {
    const title = item_title && item_title.length > 0 ? RichText.asText(item_title) : RichText.asText(link.title);

    return(
      <Item
        key={`scroll-item-${i}`}
        index={i}
        count={i + 1}
        itemCount={items.length}
        heading={title}
        link={link ? createLink(link) : undefined}
      />
    );
  };

  return (
    <PledgeProvider>
      <MetaContainer data={pageData} />
      <Wrapper itemCount={items.length}>
        <Hero
          itemCount={items.length}
          heading={RichText.asText(pageData.title)}
          background={(
            <HeroBackground
              video={get(pageData, 'video.url', '')}
              image={!isEmpty(pageData.image) && get(pageData, 'image.x1.url', '') || ''}
              description={!isEmpty(pageData.image) && get(pageData, 'image.alt', '') || ''}
            />
          )}
        />
        {!isEmpty(items) && items.map(renderItem)}
        <Outro
          heading={RichText.asText(pageData.signature_heading)}
          text={RichText.asText(pageData.signature_text)}
          completedHeading={RichText.asText(pageData.signature_thanks_heading)}
          completedText={RichText.asText(pageData.signature_thanks_text)}
          count={count}
          image={get(pageData, 'signature_background_image.hero.url', '')}
          share={(
            <InlineShare facebookLink="https://facebook.com" twitterLink="https://twitter.com" />
          )}
          onSignature={addPledge}
          itemCount={items.length}
        />
      </Wrapper>
    </PledgeProvider>
  );
};

export const query = graphql`
query IcelandicPledge($lang: String!) {
  prismic {
    allIcelandic_pledges(lang: $lang) {
      edges {
        node {
          title
          description
          image
          video {
            ...file
          }
          list {
            item_title
            item_link {
              ...link
            }
          }
          signature_heading
          signature_text
          signature_thanks_heading
          signature_thanks_text
          signature_background_image
          seo_title
          seo_description
          seo_image
          _meta {
            ...meta
          }
        }
      }
    }
  }
}
`;

IcelandicPledge.fragments = [fragmentFile, fragmentMeta, fragmentLink];

export default IcelandicPledge;
