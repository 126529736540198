import React from 'react';

import { Image } from 'components/image/Image';

import s from './Logo.scss';

export const Logo = () => (
  <div className={s('logo')}>
    <div className={s.logo__imageWrap}>
      <div className={s.logo__imageInner}>
        <Image className={s.logo__image} src={require('assets/images/pledge-logo.png')} width={60} alt="" />
      </div>
    </div>
  </div>
);
