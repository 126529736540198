import React from 'react';

interface IProps {
  src: string;
  src2x?: string;
  alt?: string;
  width?: number;
  height?: number;
  className?: string;
  draggable?: boolean;
  loading?: 'eager' | 'lazy';
}

export const Image = ({ src, src2x, alt = '', width, height, loading = 'eager', className, draggable = false }: IProps) => {
  const srcSet = !src2x ? undefined : `${src} 1x, ${src2x} 2x, ${src2x} 1200w`;

  return (
    <img
      src={src}
      srcSet={srcSet}
      alt={alt}
      width={width}
      height={height}
      className={className}
      draggable={draggable}
      loading={loading}
    />
  );
};
