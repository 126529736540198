import React, { useRef, useContext, useEffect, useState } from 'react';
import gsap from 'gsap';

import { UIContext } from 'context/ui';
import { PledgeContext } from 'context/pledge';

import { Container } from 'components/layout/Container';
import { ScrollIndicator } from 'components/scroll-indicator/ScrollIndicator';
import { ViewportEnter } from 'components/viewport-enter/ViewportEnter';

import { Logo } from '../logo/Logo';

import s from './Hero.scss';

interface IProps {
  background: React.ReactElement<any>;
  heading: string;
  itemCount: number;
}

export const Hero = ({ background, heading, itemCount }: IProps) => {
  const { isMobile, setHeaderTheme } = useContext<any>(UIContext);
  const { outroVisible, currentStep, setCurrentStep, active, setActive } = useContext<any>(PledgeContext);
  const [isAnimated, setIsAnimated] = useState(false);

  const backgroundNode = useRef<HTMLDivElement>(null);
  const backgroundInnerNode = useRef<HTMLDivElement>(null);
  const contentNode = useRef<HTMLDivElement>(null);

  const backgroundX = -(5.5 / 12) * 100;
  const backgroundPosition = '30%';

  const t = gsap.timeline();
  t.addLabel('start', 0);

  // remove timeline on unmount
  useEffect(() => {
    // clear props & reset animation in mobile
    if (isMobile) { reset(); }

    if (!isMobile) {
      animateIn(1);
      setIsAnimated(true);
    }

    return () => {
      if (t) {
        t.kill();
      }
    };
  }, [isMobile]);

  useEffect(() => {
    if (isMobile) {
      return;
    }

    if (!isAnimated) {
      if (currentStep >= 0) {
        animateIn(0, false);
        setIsAnimated(true);
      }

    } else {
      if (currentStep === itemCount) {
        reverse(false);
        setIsAnimated(false);
      }
    }

  }, [currentStep]);

  const reverse = (showContent = true) => {
    const duration = outroVisible ? 1 : 0.75;
    t.to(
      backgroundNode.current!,
      { x: '0%', ease: 'power1.inOut', transformOrigin: 'top right', duration }, 'start' );
    t.to(
      backgroundInnerNode.current!,
      { x: '0%', ease: 'power1.inOut', transformOrigin: 'top right', duration }, 'start' );
    if (showContent) {
      t.to(
        contentNode.current!,
        { y: '0%', autoAlpha: 1, ease: 'power3.inOut', duration },
        'start');
    }
  };

  // post animation
  const onComplete = () => {
    setActive(true);
    setCurrentStep(0);
  };

  const animateIn = (delay: number = 0, animateHeading: boolean = true) => {
    setActive(false);

    if (animateHeading) {
      t.fromTo(contentNode.current!, {
        autoAlpha: 0,
        y: 50,
      },
      {
        autoAlpha: 1,
        y: 0,
        ease: 'power1.inOut',
        duration: 1,
        delay,
      });
      // tslint:disable-next-line
    }
    t.addLabel('slide', animateHeading ? '+=1.5' : 0);
    t.fromTo(
      backgroundNode.current!,
      {
        x: '0%',
      },
      {
        x: `${backgroundX}%`,
        ease: 'power1.inOut',
        transformOrigin: 'top left',
        duration: 1,
      },
    'slide');
    t.fromTo(
      backgroundInnerNode.current!,
      {
        x: '0%',
      },
      {
        x: backgroundPosition,
        ease: 'power1.inOut',
        transformOrigin: 'top left',
        duration: 1,
    },
      'slide');
    t.to(
      contentNode.current!, 1,
      { y: '-100%', autoAlpha: 0,
      ease: 'power3.inOut',
      transformOrigin: 'top left',
      onComplete,
    },
      'slide');
  };

  // header theme if mobile is scrolled
  const onMobileScrolled = (direction: string) => {
    if (!isMobile) { return; }

    setHeaderTheme(direction === 'exit' ? 'dark' : 'light');
  };

  const reset = () => {
    gsap.set([
      contentNode.current!,
      backgroundNode.current!,
      backgroundInnerNode.current!,
    ], {clearProps: 'all'});
  };

  return (
    <>
      <div className={s('hero')}>
        <div className={s.hero__inner}>
          <div className={s.hero__background} ref={backgroundNode}>
            <div className={s.hero__backgroundInner} ref={backgroundInnerNode}>
              {background}
            </div>
          </div>
          <ViewportEnter
            once={false}
            threshold={0}
            onEnter={() => { onMobileScrolled('enter'); }}
            onExit={() => { onMobileScrolled('exit'); }}
          >
            <div className={s.hero__mobileScrollStart} />
          </ViewportEnter>
          <div className={s.hero__content}>
            <Container>
              <div className={s.hero__contentInner} ref={contentNode}>
                <div className={s.hero__logo}>
                  <Logo />
                </div>

                <h1 className={s.hero__heading}>{heading}</h1>
              </div>
            </Container>
          </div>
        </div>
      </div>

      <div className={s('hero__scrollIndicator', { active })}>
        <ScrollIndicator />
      </div>
    </>
  );
};
