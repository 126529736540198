import React, { useContext, useEffect, useRef } from 'react';
import Helmet from 'react-helmet';
import { ViewportEnter } from 'components/viewport-enter/ViewportEnter';

import { PledgeContext } from 'context/pledge';
import { UIContext } from 'context/ui';

import s from './Wrapper.scss';

interface IProps {
  children: React.ReactNode;
  itemCount: number;
}

export const Wrapper = ({ children, itemCount }: IProps) => {
  const { setFooterVisible, isMobile, setHeaderTheme } = useContext<any>(UIContext);
  const { currentStep, setCurrentStep, active } = useContext<any>(PledgeContext);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scroll(0, 0);
    }

    setFooterVisible(false);

    return () => {
      setFooterVisible(true);
    };
  }, []);

  useEffect(() => {
    if (currentStep >= 0 && currentStep < itemCount) {
      setHeaderTheme(isMobile ? 'dark' : 'logoLight');
    } else if (currentStep === itemCount || currentStep < 0) {
      setHeaderTheme('light');
    }

  }, [currentStep]);

  const onExit = () => {
    setHeaderTheme('light');
    setCurrentStep(itemCount);
  };

  const className = s('pledge__wrapper', { active });

  return (
    <>
      <Helmet htmlAttributes={{ class: s.pledge }} />
      <div className={className} ref={wrapperRef}>
        {children}
        <div className={s('pledge__buffer')} />
        <ViewportEnter threshold={0} onEnter={onExit} once={false}>
          <div style={{ height: 2, width: 2 }} />
        </ViewportEnter>
      </div>
    </>
  );
};
