import React, { useState } from 'react';

interface IProps {
  children: React.ReactNode;
}

export const PledgeContext = React.createContext({});

export const PledgeProvider = ({ children }: IProps) => {

  const [active, setActive] = useState(false);
  const [outroVisible, setOutroVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(-1);

  return (
    <PledgeContext.Provider
      value={{
        outroVisible,
        setOutroVisible,
        currentStep,
        setCurrentStep,
        active,
        setActive,
      }}
    >
      {children}
    </PledgeContext.Provider>
  );
};
